@tailwind base;
@tailwind components;

@tailwind utilities;


.dragBox {
    width: 250px;
    height: 100px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    font-weight: bold;
    line-height: 95px;
    color: #999;
    border: 2px dashed #ccc;
    display: inline-block;
    transition: transform 0.3s;
   
  }
  .draging {
    transform: scale(1.1);
  }
  .page {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  #form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
  }
  
  #input-file-upload {
    display: none;
  }
  
  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  }
  
  #label-file-upload.drag-active {
    background-color: #ffffff;
  }
  
  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }
  
  .upload-button:hover {
    text-decoration-line: underline;
  }
  ::-webkit-scrollbar{
    height:0px;
    width:3px
  }
  svg.star-svg {
    display: inline;
}
  .w-auto{
    width:auto !important
  }
  .b-none{
    border: none !important;
  }
  ::-webkit-scrollbar-track{
    background-color: white;
  }
  ::-webkit-scrollbar-thumb{
    background-color:  #3F3D56;
  }
  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }